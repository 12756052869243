<div *ngIf="isLoggedIn" class="sticky-message" [class.show]="messageBoxOpen">
  <a
    role="button"
    class="message-box-trigger"
    (click)="toggleMessage()"
    (keyup)="toggleMessage()"
    tabindex="">
    <span class="badge notification-badge" *ngIf="unreadCount > 0">{{
      unreadCount
    }}</span>
    <span
      class="material-symbols-outlined"
      (click)="getAll()"
      (keyup)="getAll()"
      tabindex="">
      {{ messageBoxOpen ? 'close' : 'chat' }}
    </span>
    <i
      class="bi"
      [ngClass]="messageBoxOpen ? 'bi-x-lg' : 'bi-chat-square-heart'"></i>
  </a>

  <div class="message-box">
    <ng-container *ngIf="!selectedGroup && !selectedDM">
      <div class="header">
        <h2>Messages</h2>
      </div>

      <ul ngbNav #nav="ngbNav" [activeId]="activeMenu" class="nav-tabs mf-tabs">
        <li [ngbNavItem]="1">
          <a
            ngbNavLink
            (keyup)="onTabChange(1)"
            (click)="onTabChange(1)"
            tabindex=""
            class="nav-link">
            All Chats
          </a>
          <ng-template ngbNavContent>
            <ng-container *ngIf="!selectedGroup && !selectedDM">
              <div class="search-bar">
                <input
                  #searchElement
                  type="text"
                  class="form-control shadow-none top-header-search-input"
                  placeholder="Search"
                  [(ngModel)]="searchTerm"
                  (keyup)="searchall($event)" />
                <span class="material-symbols-outlined" tabindex="1"
                  >search</span
                >
              </div>

              <div class="chat-box">
                <div class="chat-list" #messageBox (scroll)="onScroll()">
                  <ng-container *ngIf="isApiInProgress">
                    <ngx-skeleton-loader [count]="count"></ngx-skeleton-loader>
                  </ng-container>
                  <ng-container *ngIf="searching">
                    <ngx-skeleton-loader [count]="count"></ngx-skeleton-loader>
                  </ng-container>
                  <div class="noUser" *ngIf="noUserFound">No user found</div>
                  <ng-container *ngIf="!noUserFound && !isApiInProgress">
                    <div
                      class="chat-item"
                      *ngFor="let group of itemData"
                      (click)="handleChatItemClick(group)"
                      tabindex="0"
                      (keyup.enter)="handleChatItemClick(group)">
                      <img
                        [src]="
                          group.profileImage || './assets/groups_default.jpg'
                        "
                        [alt]="group.name"
                        class="user-icon" />
                      <ng-container *ngIf="group?.type === 'private'">
                        <span
                          class="status-indicator"
                          [ngClass]="{
                            online: group?.status === 'online',
                            offline: group?.status === 'offline'
                          }">
                        </span>
                      </ng-container>
                      <div class="chat-info">
                        <span class="chat-name">{{ group?.name }}</span>
                        <ng-container
                          *ngIf="
                            group?.message?.messageAttachmentURL;
                            else messageText
                          ">
                          <span class="chat-message">Sent</span>
                        </ng-container>
                        <ng-template #messageText>
                          <ng-container
                            *ngIf="
                              group?.message?.messageText;
                              else whitespace
                            ">
                            <span
                              class="chat-message"
                              [ngClass]="{
                                'new-message': group?.message?.isNew
                              }"
                              >{{ group?.message?.messageText }}</span
                            >
                          </ng-container>
                          <ng-template #whitespace>
                            <span class="chat-message">&nbsp;</span>
                          </ng-template>
                        </ng-template>
                      </div>
                      <div class="chat-meta">
                        <span class="chat-time">{{
                          group.message.createdAt  | UTCToLocalTime | date: 'hh:mm' 
                        }}</span>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </div>
            </ng-container>
          </ng-template>
        </li>

        <li [ngbNavItem]="2">
          <a
            ngbNavLink
            (keyup)="onTabChange(2)"
            (click)="onTabChange(2)"
            tabindex=""
            class="nav-link">
            Groups
          </a>
          <ng-template ngbNavContent>
            <ng-container *ngIf="!selectedGroup && !selectedDM">
              <div class="search-bar">
                <input
                  [(ngModel)]="searchTerm"
                  (keyup)="searchGr($event)"
                  type="text"
                  class="form-control top-header-search-input"
                  placeholder="Search" />
                <span class="material-symbols-outlined" tabindex="1"
                  >search</span
                >
              </div>

              <div class="chat-box">
                <div class="chat-list" #messageBoxGroup (scroll)="onScrollGroup()">
                  <ng-container *ngIf="isApiInProgress">
                    <ngx-skeleton-loader [count]="count"></ngx-skeleton-loader>
                  </ng-container>
                  <ng-container *ngIf="searching">
                    <ngx-skeleton-loader [count]="count"></ngx-skeleton-loader>
                  </ng-container>
                  <div class="noUser" *ngIf="noUserFound">No group found</div>

                  <ng-container *ngIf="!noUserFound">
                    <div
                      class="chat-item"
                      *ngFor="let group of myGroups"
                      (click)="openGroupChat(group)"
                      tabindex="0"
                      (keyup.enter)="openGroupChat(group)">
                      <img
                        [src]="
                          group.profileImage || './assets/groups_default.jpg'
                        "
                        [alt]="group.name"
                        class="user-icon" />
                      <div class="chat-info">
                        <span class="chat-name">{{ group?.name }}</span>
                        <ng-container
                          *ngIf="
                            group?.message?.messageAttachmentURL;
                            else messageText
                          ">
                          <span class="chat-message">Sent</span>
                        </ng-container>
                        <ng-template #messageText>
                          <ng-container
                            *ngIf="
                              group?.message?.messageText;
                              else whitespace
                            ">
                            <span
                              class="chat-message"
                              [ngClass]="{
                                'new-message': group?.message?.isNew
                              }"
                              >{{ group?.message?.messageText }}</span
                            >
                          </ng-container>
                          <ng-template #whitespace>
                            <span class="chat-message">&nbsp;</span>
                          </ng-template>
                        </ng-template>
                      </div>
                      <div class="chat-meta">
                        <span class="chat-time">{{
                          group.message.createdAt | date: 'hh:mm '
                        }}</span>
                        <!-- <span class="status-indicator online"></span> -->
                      </div>
                    </div>
                  </ng-container>
                </div>
              </div>
            </ng-container>
          </ng-template>
        </li>
      </ul>
      <div [ngbNavOutlet]="nav" class="mt-2"></div>
    </ng-container>

    <ng-container *ngIf="selectedGroup">
      <app-group-chat
        [group]="selectedGroup"
        (closeChat)="handleCloseChat()"></app-group-chat>
    </ng-container>

    <ng-container *ngIf="selectedDM">
      <app-all-chat-messages
        [selectedDM]="selectedDM"
        (closeChat)="handleCloseChat()"></app-all-chat-messages>
    </ng-container>
  </div>
</div>
