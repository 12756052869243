'use strict';

export const moduleManagementComposition = 'modulesetup';

export const menuManagemenComposition = 'menusetup';

export const roleManagementComposition = 'rolesetup';

export const captureAllButCSSComposition = 'all_but_css';

export const captureAllComposition = 'all';

export const captureImagesComposition = 'only_images';

export const captureUrlComposition = 'url';

export const captureVisualTestComposition = 'visual_text';

export const captureChatGPTComposition = 'chat_gpt';

export const captureBoomGPTComposition = 'boomgpt';

export const boomFolderComposition = 'boomFolder';

export const chatGPTCategoryComposition = 'chatgpt_category';

export const sharedPostComposition = 'boom_shared_post';

export const sharedGroupComposition = 'boom_shared_group_post';

export const followingComposition = 'my_following';

export const groupsComposition = 'connectionGroup';

export const notificationComposition = 'fs_notification';

export const webCommentsComposition = 'boomext_web_comments';

export const sharedPostCommentComposition = 'sharedPostComment';

export const sharedPostGroupCommentComposition = 'sharedPostGroupComment';

export const signupInvitationComposition = 'signup_invitation';
export const userInvitationComposition = 'invitation';

export const sharedPostLikeComposition = 'sharedPostLike';

export const sharedPostCommentLikeComposition = 'sharedPostCommentLike';

export const sharedPostGroupLikeComposition = 'sharedPostGroupLike';

export const sharedPostGroupCommentLikeComposition =
  'sharedPostGroupCommentLike';

// This was used on old codes, so this was not used on new code for captured note composition
export const captureNoteComposition = 'capture_note';
// Since the use of captureNoteComposition was unknown in old codes, new composition name was created below.
export const capturedNotesComposition = 'captured_notes';

export const withdrawaRequestCompostion = 'agent_request_withdraw';
export const withdrawaCompleteCompostion = 'paid_withdrawal';
export const packageCompleteCompostion = 'subscription_package';
export const verifyEmailComposition = 'verify_email';
export const inviteFriendsCompletionComposition = 'inviteFriends_last_step';
export const emailInviteesComposition = 'invitees';
export const paymentMethodComposition = 'payment_method';
export const requestQuoteComposition = 'quoteRequest';
export const emailTemplateComposition = 'email_template';
export const customEmailTemplateComposition = 'custom_email_template';

// product limits
export const productSpaceComposition = 'product_space';
export const productFoldersComposition = 'product_folders';
export const productContactsComposition = 'product_contacts';
export const productMapsComposition = 'product_maps';
export const productAIChatsComposition = 'product_AI_chats';
export const productSharedFoldersComposition = 'product_shared_folders';
export const productGroupsComposition = 'product_groups';
export const productUploadFilesComposition = 'product_upload_files';
export const productUploadFileSizeComposition = 'product_upload_file_size';
export const productImageCaptureComposition = 'product_image_capture';
export const productFullPageComposition = 'product_full_page';
export const productUsersComposition = 'product_users';
export const productHumanChatsComposition = 'product_human_chats';
export const productExportComposition = 'product_export';

// Projects
// export const employerProjectComposition = 'employerProject'
export const projectBoardComposition = 'projectBoard';
export const projectIssueComposition = 'projectIssue';
export const projectSprintComposition = 'projectSprint';
export const projectIssueTimerComposition = 'issueTimer';

//userDetais
export const UserDetailsComposition = 'user_details';

export const captureDocumentaionComposition = 'the_createDocument';
