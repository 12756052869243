import { Component, HostListener, OnInit } from '@angular/core';
import { ConsoleToggleService } from './services/console-toggle.service';
import { init } from 'mftsccs-browser';
import { environment } from 'src/environments/environment';
import { ItemService } from './services/item.service';
import { AuthService } from './auth/auth.service';
import { Router } from '@angular/router';
import { SocketService } from './services/socket.service';
import { NotificationService } from './services/notification.service';

@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet>',
})
export class AppComponent implements OnInit {
  title = 'Dashboard';
  sessionId: any;

  @HostListener('window:boom_storage_changed', ['$event'])
  StorageChanged(event: any) {
    setTimeout(() => {
      const profile: any = localStorage.getItem('profile');
      console.log(profile, 'ppppp');

      if (JSON.parse(profile)?.token) {
        if (this._router.url == '/login' || this._router.url == '/signup') {
          window.location.reload();
        }
      } else {
        if (this._router.url != '/login') {
          console.log('So, lets redirect to login page...');
          window.location.reload();
        }
      }
    }, 500);
  }

  constructor(
    private _consoleToggleService: ConsoleToggleService,
    private _itemService: ItemService,
    private _authService: AuthService,
    private _router: Router,
    private _socketService: SocketService, //listening for socket
    private _notificationService: NotificationService //listening for notification from socket
  ) {
    this._consoleToggleService.disableConsoleInProduction();
  }

  ngOnInit(): void {
    const profile: any = localStorage.getItem('profile');
    const accessToken = JSON.parse(profile);
    init(
      environment.baseDomain,
      environment.aiDomain,
      accessToken?.token,
      environment.baseDomain
    );
    this.getSessionId();
    if (!this._authService.isLoggedIn() && !this.sessionId) {
      this.createSessionId();
    }
    // this.checkLocalStorage();
  }

  checkLocalStorage() {
    window.addEventListener('storage', event => {
      if (event?.key === 'profile' && event?.url?.includes('kakhagagha1234'))
        window.location.reload();
    });
  }

  getSessionId() {
    // Split cookie string and get all individual name=value pairs in an array
    const cookieArr = document.cookie.split(';');

    // Loop through the array elements
    for (let i = 0; i < cookieArr.length; i++) {
      const cookiePair = cookieArr[i].split('=');
      // console.log('cookiePair', cookiePair)

      /* Removing whitespace at the beginning of the cookie name
      and compare it with the given string */
      if (cookiePair[0].trim() === 'SessionId') {
        // Decode the cookie value and return
        // return decodeURIComponent(cookiePair[1]);
        this.sessionId = decodeURIComponent(cookiePair[1]);
        // console.log('sessionId', this.sessionId)
        return;
        // return decodeURIComponent(cookiePair[1]);
      }
    }

    // Return null if not found
    // return null;
  }

  createSessionId() {
    this._itemService.createSessionId().subscribe({
      next: (response: any) => {
        // console.log('response createSessionId', response);
      },
      error: (err: any) => {
        console.log(err);
      },
    });
  }
}
