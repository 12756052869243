import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpEvent,
  HttpResponse,
  HttpRequest,
  HttpHandler,
  HttpErrorResponse,
} from '@angular/common/http';
import {
  BehaviorSubject,
  Observable,
  catchError,
  filter,
  switchMap,
  take,
  tap,
  throwError,
} from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { Router } from '@angular/router';
import { IUser } from '../interfaces/IUser.interface';
import { updateAccessToken } from 'mftsccs-browser';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  urlsToNotUse: Array<string> = ['/api/auth/signup'];
  private isRefreshing = false;
  private isSyncing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );

  constructor(
    private _auth: AuthService,
    private _router: Router
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const profile = this._auth.loadFromLocalStorage();

    if (profile.token) {
      request = this.addToken(request, profile.token);
    }

    return next.handle(request).pipe(
      catchError(error => {
        if (error instanceof HttpErrorResponse && error.status === 401) {
          return this.handle401Error(request, next);
        } else {
          return throwError(error);
        }
      })
    );
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      return this._auth.refreshToken().pipe(
        switchMap((x: any) => {
          this.isRefreshing = false;
          let fromStorage = this._auth.loadFromLocalStorage();
          let model: IUser = {
            ...fromStorage,
            token: x?.data.accessToken,
            refreshToken: x?.data.refreshToken,
          };
          this._auth.saveToLocalStorage(model);
          this.refreshTokenSubject.next(x?.data.accessToken);
          window.dispatchEvent(new Event('set_storage'));
          updateAccessToken(this._auth.loadFromLocalStorage()?.token);
          return next.handle(this.addToken(request, x?.data.accessToken));
        }),
        catchError(error => {
          this.isRefreshing = false;
          if (error.status == '406') {
            // this.handle406Error(request, next);
            this._auth.logout();
          }
          return throwError(() => error);
        })
      );
    } else {
      return this.refreshTokenSubject.pipe(
        filter(token => token != null),
        take(1),
        switchMap(token => {
          return next.handle(this.addToken(request, token));
        })
      );
    }
  }

  handle406Error(request: HttpRequest<any>, next: HttpHandler) {
    window.dispatchEvent(new Event('get_storage'));
    //  if (confirm("Your token is expired. Reload.") == true) {
    //   window.location.reload();
    // }
  }

  private addToken(request: HttpRequest<any>, token: string) {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
}
