<!-- messages.component.html -->
<div class="group-chat-container">
  <div class="group-chat-header">
    <span
      class="material-symbols-outlined more-options"
      (click)="closeGroupChat()"
      (keyup)="closeGroupChat()"
      tabindex="0"
      >chevron_left</span
    >
    <img
      [src]="group?.profileImage || './assets/groups_default.jpg'"
      [alt]="group.name"
      class="group-icon" />
    <span class="group-name">{{ group.name }}</span>
    <span
      class="material-symbols-outlined more-options"
      (click)="openGroupMembers()"
      tabindex=""
      (keyup)="openGroupMembers()">
      group</span
    >

    <!-- <span
      class="status-indicator"
      [ngClass]="{ online: group.satus==='online' , offline: group.status === 'offline' }"></span> -->

    <!-- <div ngbDropdown placement="left-top" class="d-inline-block">
      <button type="button" class="more-options" ngbDropdownToggle>
        <span class="material-symbols-outlined"> more_vert </span>
      </button>
      <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
        <button ngbDropdownItem (click)="openGroupMembers()">
          Group Members
        </button>
      </div>
    </div> -->
  </div>

  <div class="group-chat-content" #chatArea (scroll)="onScroll()">
    <ng-container *ngIf="isApiInProgress">
      <ngx-skeleton-loader [count]="count"></ngx-skeleton-loader>
    </ng-container>
    <ng-container *ngIf="messages?.length === 0 && !isApiInProgress">
      <div class="no-conversation-container">
        <div class="no-conversation-content">
          <img
            src="./assets/icons/no_chat.png"
            alt="No Conversation"
            class="no-conversation-icon" />
          <h1>No conversation</h1>
          <p>
            There are no conversations yet. Start a new conversation to get
            things going.
          </p>
        </div>
      </div>
    </ng-container>

    <div
      class="message"
      *ngFor="let message of messages"
      [ngClass]="{
        'message-owner': message.sender.entityId === entityId,
        'message-other': message.sender.entityId !== entityId
      }">
      <ng-container *ngIf="message?.sender?.entityId !== entityId">
        <img
          *ngIf="message?.sender?.profileImg; else noProfileImage"
          [src]="message?.sender?.profileImg"
          alt=""
          class="message-avatar" />
        <ng-template #noProfileImage>
          <img class="message-avatar" src="./assets/man.png" alt="" />
        </ng-template>
      </ng-container>
      <div class="message-bubble">
        <ng-container *ngIf="message?.sender?.entityId !== entityId">
            <span class="message-sender">
              {{ message?.sender?.firstName }} {{ message?.sender?.lastName }}
            </span>
        </ng-container>
        <div class="message-text-container">
          <div class="message-content">
            <span class="message-text chat-msg-text">{{ message?.text }}</span>
            <span class="message-time">{{
              message?.createdAt | UTCToLocalTime | date: 'hh:mm'
            }}</span>
          </div>
          <div
            class="options-card"
            *ngIf="message.sender.entityId === entityId">
            <div class="options">
              <a
                *ngIf="message.filename"
                title="Download"
                tabindex="0"
                (keyup)="downloadFile(message.fileurl, message.filename)"
                (click)="downloadFile(message.fileurl, message.filename)"
                class="download-link">
                <span class="download-icon material-symbols-outlined">
                  download
                </span>
              </a>
              <a title="Delete">
                <span
                  class="material-symbols-outlined"
                  (click)="deleteMessage(message)"
                  (keyup)="deleteMessage(message)"
                  tabindex="0">
                  delete
                </span>
              </a>
              <a>
                <span
                  class="material-symbols-outlined"
                  (click)="enterEditMode(message)"
                  (keyup)="enterEditMode(message)"
                  tabindex="0">
                  edit_square
                </span>
              </a>
            </div>
          </div>
        </div>
        
        <ng-container *ngIf="message.filename">
          <ng-container *ngIf="message.type === 'image'; else pdfOrOther">
            <div class="file-container">
              <a
                  tabindex="0"
                  (keyup)="openImageModal(message.fileurl, message.sender)"
                  (click)="openImageModal(message.fileurl, message.sender)">
                  <img [src]="message.fileurl" class="image" alt="Image file" />
                </a>
           
            </div>
          </ng-container>
          <ng-template #pdfOrOther>
            <ng-container *ngIf="message.type === 'file'">
              <div class="file-container">
                <div class="pdf-container-msg">
                  <a href="{{ message.fileurl }}" target="_blank">
                    <span class="filename">{{ message.filename }}</span>
                  </a>
                </div>
              </div>
            </ng-container>
          </ng-template>
        </ng-container>
      </div>
      <!-- <div class="options-card" *ngIf="message.sender.entityId === entityId">
        <div class="options">
          <a
            *ngIf="message.filename"
            title="Download"
            tabindex="0"
            (keyup)="downloadFile(message.fileurl, message.filename)"
            (click)="downloadFile(message.fileurl, message.filename)"
            class="download-link">
            <span class="download-icon material-symbols-outlined">
              download
            </span>
          </a>
          <a title="Delete">
            <span
              class="material-symbols-outlined"
              (click)="deleteMessage(message)"
              (keyup)="deleteMessage(message)"
              tabindex="0">
              delete
            </span>
          </a>
          <a>
            <span
              class="material-symbols-outlined"
              (click)="enterEditMode(message)"
              (keyup)="enterEditMode(message)"
              tabindex="0">
              edit_square
            </span>
          </a>
        </div>
      </div> -->

      <!-- </div> -->
      <div
        class="options-download download-only"
        *ngIf="message.sender.entityId !== entityId">
        <div class="download">
          <a
            *ngIf="message.filename"
            title="Download"
            tabindex="0"
            (keyup)="downloadFile(message.fileurl, message.filename)"
            (click)="downloadFile(message.fileurl, message.filename)"
            class="download-link">
            <span class="download-icon material-symbols-outlined">
              download
            </span>
          </a>
        </div>
      </div>
    </div>
  </div>
  <div class="chat-footer">
    <div class="input-container">
      <span
        class="material-symbols-outlined attach-icon"
        (keyup)="fileUploadInput.click()"
        (click)="fileUploadInput.click()"
        tabindex="0">
        <input
          type="file"
          #fileUploadInput
          (change)="handleFileInput($event)"
          accept="image/*, application/pdf"
          style="display: none" />
        attach_file
      </span>

      <div class="pdf-container" *ngIf="pdf">
        <span
          class="material-symbols-outlined close-pdf"
          (click)="remove()"
          (keyup)="remove()"
          tabindex="0">
          close
        </span>
        <span>{{ pdf }}</span>
      </div>
      <textarea
        #messageInput
        placeholder="Type your message..."
        class="chat-input"
        [(ngModel)]="newMessage"
        (input)="adjustInputHeight($event)"
        (keyup.enter)="editingMessage ? updateMessage() : sendMessage()"
        [style.backgroundImage]="
          previewImage ? 'url(' + previewImage + ')' : 'none'
        "
        [style.backgroundSize]="previewImage ? '50px 50px' : 'none'"
        [style.backgroundRepeat]="'no-repeat'"
        [style.backgroundPosition]="
          previewImage ? 'left 10px top 10px' : 'left 10px center'
        "
        [style.paddingTop]="previewImage ? '60px' : '8px'"
        rows="1"></textarea>

      <span
        (keyup)="remove()"
        tabindex="0"
        class="remove-icon material-symbols-outlined"
        *ngIf="previewImage"
        (click)="remove()">
        close
      </span>
      <img
        src="./assets/boomcosole-icon.png"
        alt="Send"
        class="send-icon"
        [class.disabled]="!newMessage"
        [ngClass]="{ highlighted: newMessage || fileUrl || previewImage }"
        (click)="editingMessage ? updateMessage() : sendMessage()"
        tabindex=""
        (keyup.enter)="editingMessage ? updateMessage() : sendMessage()" />
    </div>
  </div>
</div>
