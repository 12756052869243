// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// import { access } from 'fs/promises';

export const environment = {
  // STRIPE_PUBLISHABLE_KEY:
  // 'pk_live_51OZiD3Gbo8vMaYaxNov7AU8QpdKHyx93L1CSDAEq51zICpiaSN9DV9wgXKZZ7Yv5qwAO6lXM64zOqStFyvPxR4wy00vLgwwG9M',
  STRIPE_PUBLISHABLE_KEY:
    'pk_test_51PeyK5KzBBeLqm7tQsqPGfoVLIMOb4eQDLGPMwQfd5U9ir7sZ8HTw7a4KUyfDzODyAQpwdwtker1hJ6q9MAo9bvz00gIs0FSIF', // test
  production: false,

  MFTSCCS_BASE_URL: 'https://theta.boomconcole.com',
  nodeUrl: 'https://theta.boomconcole.com/api/v1',
  // nodeUrl: 'http://localhost:5000/api/v1',

  // socketUrl: 'http://localhost:5000',
  socketUrl: 'https://theta.boomconcole.com',

  google_client_id:
    '361820163866-b2vtkttp33rkcdolmtev3r4gijqcmq5i.apps.googleusercontent.com',
  // webURL: 'http://localhost:4000',
  baseDomain: 'https://theta.boomconcole.com',

  // baseDomain: 'http://localhost:3000',

  baseUrl: 'https://theta.boomconcole.com/api',
  imageBaseUrl: 'https://theta.boomconcole.com',
  aiDomain: 'https://www.devai.freeschema.com',
  aiUrl: `https://www.devai.freeschema.com/api`,

  // MFTSCCS_BASE_URL: 'https://boomconsole.com',
  // socketUrl: 'https://boomconsole.com',
  // baseDomain: 'https://boomconsole.com',
  // baseUrl: 'https://boomconsole.com/api',
  // imageBaseUrl: 'https://boomconsole.com',
  // aiDomain: 'https://www.ai.freeschema.com',
  // aiUrl: 'https://www.ai.freeschema.com/api',
  // nodeUrl: 'https://boomconsole.com/api/v1',
  // google_client_id:
  //   '361820163866-b2vtkttp33rkcdolmtev3r4gijqcmq5i.apps.googleusercontent.com',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
