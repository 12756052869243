'use strict';

// [linker_name]_s for forward connection AND [linker_name]_by for reverse connection

export const sharedBoomgptData = 'shared_boomgpt_data';

// user <--> feed's to data
export const sharedBoomgptUser = 'shared_boomgpt_post';
// user <--> comment's
export const sharedBoomgptCommentUser = 'shared_boomgpt_post_comment';
// group <-to-> feed
export const groupSharedBoomgpt = 'group_shared_boomgpt';
// group <--> feed's to data
export const groupSharedBoomgptData = 'group_shared_boomgpt_data';
// group <--> users
export const groupMembers = 'group_members';
// group <--> users
export const requestedGroupMembers = 'requested_group_members';

// Folders <--> users <---> Captures
export const linkerMyConsole = 'my_console';
export const linkerConsoleFolder = 'console_folder';

// user to likep
export const likeBoomgptSharedData = 'like_boomgpt_shared_post';
//  comment to shared post
export const sharedPostCommentLinker = 'comment_boom_shared_post';
// comment for group shared post
export const sharedPostGroupCommentLinker = 'comment_boom_shared_group_post';

export const sharedPostLikeLinker = 'like_boom_shared_post';

export const sharedPostCommentLike = 'like_boom_shared_post_comment';

export const sharedPostGroupLikeLinker = 'like_boom_shared_group_post';

export const sharedPostGroupCommentLink = 'like_boom_shared_group_post_comment';

// Single Capture <--- Note
export const linkerCaptureNote = 'note_on_capture';
// Single Capture <--- Note for new note link
export const linkerCapturedNotes = 'captured_notes';
// Capture Note <--- user
export const linkerUserCaptureNote = 'capture_note_added_by_user';

export const linkerAgreementCheck = 'agent_term_agree';

export const linkerWithdrawRequest = 'withdraw_request';
export const linkerWithdrawComplete = 'withdrawal_complete';
export const linkerPackage = 'get_package';
export const linkerPackageToProduct = 'package_product';
export const linkerEmailVerification = 'email_verify';
export const linkerEmailInvitation = 'email_invite';
export const linkerUserInvitation = 'invite';
export const linkerInviteFriendsCompletion = 'inviteFriends_steps_complete';
export const linkerFriend = 'friend';
export const whatsappChatLinker = 'whatsapp_chat';
export const paymentMethodLinker = 'has_payment_method';

//send invite linkers
export const linkerFriendEmailInvitation = 'friend_invite';
export const linkerAgentEmailInvitation = 'agent_invite';

export const linkerAffiliate = 'affiliate';

// product feature linkers
export const linkerProductSpaceLimit = 'space_limit';
export const linkerProductFolderLimit = 'folder_limit';
export const linkerProductContactLimit = 'contact_limit';
export const linkerProductMapLimit = 'map_limit';
export const linkerAIChatLimit = 'AI_chat_limit';
export const linkerSharedFolderLimit = 'shared_folder_limit';
export const linkerGroupLimit = 'group_limit';
export const linkerUploadFileLimit = 'upload_file_limit';
export const linkerUploadFileSizeLimit = 'upload_file_size_limit';
export const linkerImageCaptureLimit = 'image_capture_limit';
export const linkerFullPageLimit = 'full_page_limit';
export const linkerUserLimit = 'user_limit';
export const linkerHumanChatLimit = 'human_chat_limit';
export const linkerExportLimit = 'export_limit';

// Access Management Links
export const linkerReadAccess = 'read_access';
export const linkerWriteAccess = 'write_access';

// for limitations
export const linkerFilesUpload = 'system_files_upload';

export const productPriceDurations = [
  { text: 'Month', value: 'month' },
  { text: 'Year', value: 'year' },
  { text: '4-Year', value: 'fouryear' },
];

// Accounting
export const payableCreditLinker = 'payable_credit';
export const receivableDebitLinker = 'receivable_debit';

//user
export const userDetails = 'detail';

//sub user
export const subUserEntity = 'the_entity_s_user';

//
export const compBoomFolder = 'boomFolder';
