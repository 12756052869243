import { Component, OnDestroy, OnInit } from '@angular/core';
import { captureBoomGPTComposition } from 'src/app/shared/constants/composition.const';
import { Store } from '@ngrx/store';
import { captureTypes } from 'src/app/shared/data/enums';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/auth/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ItemService } from 'src/app/services/item.service';
import { LoginModalComponent } from './login-modal/login-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-view-copied-link',
  templateUrl: './view-copied-link.component.html',
  styleUrls: ['./view-copied-link.component.scss'],
})
export class ViewCopiedLinkComponent implements OnInit, OnDestroy {
  private subs!: Subscription;

  baseUrl = environment.imageBaseUrl;
  active!: number;
  selectedContact: any;
  items: any;
  composition: string = captureBoomGPTComposition;
  dataLoaded: boolean = false;
  commentId!: string;
  compositionId!: string;
  currentCaptureId: any;
  dismissedLoginModal: boolean = false;
  image: any;
  whatsappMessages: any;
  whatsappCurrentCount: number = 0;
  whatsappTotalCount: number = 0;
  comments!: any;
  username!: string;
  dataType = captureTypes;
  isLoggedIn: boolean = false;
  crmContactInfo: any;

  constructor(
    private _authService: AuthService,
    private route: ActivatedRoute,
    private _items: ItemService,
    private _store: Store,
    private _router: Router,
    private modalService: NgbModal
  ) {}

  formatTextWithLineBreaksAndBoldFirst(text: any, keyword: any) {
    if (text) {
      const index = text.indexOf(keyword);
      if (index !== -1) {
        const secondIndex = text.indexOf(keyword, index + keyword.length);
        let formattedText =
          text.slice(0, index) +
          '<strong>' +
          text.slice(index, index + keyword.length) +
          '</strong>';
        if (secondIndex !== -1) {
          formattedText += text.slice(index + keyword.length, secondIndex);
        } else {
          formattedText += text.slice(index + keyword.length);
        }
        formattedText += text
          .slice(secondIndex + keyword.length)
          .replace(/\n\s*\n/g, '<br>')
          .replace(/\n/g, ' ');
        return formattedText;
      }
    }
    return '';
  }

  ngOnInit() {
    if (this._authService.isLoggedIn()) {
      this.isLoggedIn = true;
    } else {
      this.openLoginModal();
    }

    this.route.queryParams.subscribe((params: any) => {
      this.username = params['name'];
      this.image = params['profile'];
    });

    this.currentCaptureId = this.route.snapshot.paramMap.get('id');
    this.getById();
  }

  getById() {
    this._items.getByIdClean(this.currentCaptureId).subscribe({
      next: (_: any) => {
        this.dataLoaded = true;
        this.items = _?.data[this.composition];
        this.getDataForHtml(_?.data);
        this.compositionId = _?.[this.composition]?.id;
      },
      error: () => {
        this.dataLoaded = true;
      },
    });
  }

  goBack() {
    this._router.navigate(['/captures/boomgpt'], {
      queryParams: { s: this.items?.boom_folder?.category_id },
    });
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
  openLoginModal() {
    this.dataLoaded = true;
    this.modalService.open(LoginModalComponent, { size: 'md' });
  }

  getDataForHtml(data: any) {
    const contactData = data?.boomgpt?.crm_data;
    const companyData = data?.boomgpt?.crm_data.companyInformation;

    // ! FOR DATA INSIDE COMPANYiNFORMATION
    const compWebsite = companyData?.website;
    const sector = companyData?.sector;
    const location = companyData?.location;
    const companyName = companyData?.name;
    const companyPhone = companyData?.phone?.data?.map((x: any) => ({
      id: x.id,
      phone: x?.data?.phone,
    }));
    const companyEmails = companyData?.email?.data?.map((x: any) => ({
      id: x.id,
      email: x?.data?.email,
    }));

    const contactPersonData = companyData?.contactPerson;

    // ! FOR CONTACT INFORMATION
    const tag = contactData?.tag;
    const link = contactData?.link;
    const email = contactData?.email;
    const name = contactData?.name;
    const avatar = contactData?.avatar;
    const addresses = contactData?.addresses?.map((x: any) => ({
      id: x.id,
      location: x?.location,
    }));

    const phone = contactData?.phone?.map((x: any) => ({
      id: x.id,
      number: x?.number,
    }));

    const notes = contactData?.notes?.map((x: any) => ({
      id: x.id,
      title: x?.title,
      details: x?.details,
    }));

    const notesId = contactData?.notes?.id;

    const about = contactData?.about;
    const founded = contactData?.founded;
    const companySize = contactData?.companySize;
    const companyWebsite = contactData?.companyWebsite;
    const industry = contactData?.industry;
    const workWellbeing = contactData?.workWellbeing;
    const ceo = contactData?.ceo;
    const companyRevenue = contactData?.companyRevenue;
    const website = contactData?.website;
    const ratingAndReviews = contactData?.ratingAndReviews;
    const latitude = contactData?.latitude;
    const longitude = contactData?.longitude;
    const url = contactData?.url;
    const experience = contactData?.experience;
    const education = contactData?.education;
    const isCompany = contactData?.isCompany;
    const contactLinks = contactData?.contactLinks;

    const crmContactInfo = {
      company: {
        id: companyData?.id,
        name: companyName,
        website: compWebsite,
        sector: sector,
        location: location,
        phone: companyPhone,
        emails: companyEmails,
        contactPersons: contactPersonData,
      },
      contact: {
        id: data?.boomgpt?.crm_data?.id,
        tag: tag,
        link: link,
        email: email,
        name: name,
        avatar: avatar,
        addresses: addresses,
        phones: phone,
        notes: notes,
        about: about,
        founded: founded,
        companySize: companySize,
        companyWebsite: companyWebsite,
        industry: industry,
        workWellbeing: workWellbeing,
        ceo: ceo,
        companyRevenue: companyRevenue,
        website: website,
        ratingAndReviews: ratingAndReviews,
        latitude: latitude,
        longitude: longitude,
        url: url,
        experience: experience,
        education: education,
        notesId: notesId,
        isCompany: isCompany,
        contactLinks: contactLinks,
      },
    };

    removeUndefined(crmContactInfo);

    function removeUndefined(obj: any) {
      for (const prop in obj) {
        if (obj[prop] === undefined) {
          delete obj[prop];
        } else if (typeof obj[prop] === 'object') {
          removeUndefined(obj[prop]);
        }
      }
    }

    return (this.crmContactInfo = crmContactInfo);
  }
}
